import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Devices from "@/views/Devices/Devices.vue";
import RepairTickets from "@/views/Repairs/RepairTickets.vue";
import ServiceContracts from "@/views/Services/ServiceContracts.vue";
import store from "@/store";
import ViewDevice from "@/views/Devices/ViewDevice.vue";
import ViewRepairTicket from "@/views/Repairs/ViewRepairTicket.vue";
import OAuthCallback from "@/views/OAuthCallback.vue";
import getEnv from "@/utils/env";
import ViewContract from "@/views/Services/ViewContract.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Dashboard,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/callback",
    name: "OAuthCallback",
    component: OAuthCallback,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/devices",
    name: "Devices",
    component: Devices,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: ":id",
        name: "ViewDevice",
        component: ViewDevice,
      },
    ],
  },
  {
    path: "/repairs",
    name: "RepairTickets",
    component: RepairTickets,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: ":id",
        name: "ViewRepairTicket",
        component: ViewRepairTicket,
      },
    ],
  },
  {
    path: "/contracts",
    name: "ServiceContracts",
    component: ServiceContracts,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: ":id",
        name: "ViewContract",
        component: ViewContract,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((routeTo, routeFrom, next) => {
  store.commit("dropdowns/CLOSE_ALL_DROPDOWN");
  const redirectToLogin = () => {
    const COGNITO_REDIRECT_URI = getEnv("VUE_APP_COGNITO_REDIRECT_URI");
    const COGNITO_CLIENT_ID = getEnv("VUE_APP_COGNITO_CLIENT_ID");
    const COGNITO_APP_DOMAIN = getEnv("VUE_APP_COGNITO_APP_DOMAIN");
    window.location.href = `https://${COGNITO_APP_DOMAIN}/oauth2/authorize?client_id=${COGNITO_CLIENT_ID}&redirect_uri=${COGNITO_REDIRECT_URI}&response_type=code&scope=openid`;
  };

  const redirectToDashboard = () => {
    next({ name: "Dashboard" });
  };

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  const authUser = store.getters["auth/authUser"];

  if (!authRequired) {
    if (authUser) {
      return redirectToDashboard();
    }

    return next();
  }

  if (authUser) {
    return next();
  } else {
    return redirectToLogin();
  }
});

export default router;
