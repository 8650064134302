import { MutationTree } from "vuex";
import { DropdownState } from "@/store/modules/dropdowns/types";

export const mutations: MutationTree<DropdownState> = {
  TOGGLE_DROPDOWN(state: DropdownState, dropdown: string): boolean {
    state[dropdown] = !state[dropdown];
    return state[dropdown];
  },
  CLOSE_ALL_DROPDOWN(state: DropdownState): DropdownState {
    state.header_user = false;
    state.header_notification = false;
    state.filters = false;
    return state;
  },
};
