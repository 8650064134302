
import { defineComponent } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ViewDevice",
  components: {
    BaseIcon,
  },

  setup(props, context) {
    const route = useRouter();

    const close = () => {
      context.emit("close");
    };
    return {
      close,
      route,
    };
  },
});
