import { ActionTree } from "vuex";
import { AuthState } from "@/store/modules/auth/types";
import { RootState } from "@/store/types";

export const actions: ActionTree<AuthState, RootState> = {
  logout({ commit }): void | Promise<void> {
    try {
      commit("SET_AUTH_USER", null);
    } catch (err) {
      if (err instanceof Error) {
        const message = err.message;
        return Promise.reject(new Error(message));
      }
    }
  },
};
