<template>
  <button
    @click="toggleDropdown"
    class="dashboard__main__header__dropdown__button"
  >
    <div tabindex="0" class="dashboard__main__header__button__container">
      <div class="user__initials dashboard__main__header__button__img">KP</div>
      <div class="dashboard__main__header__button__down">
        <BaseIcon
          icon="arrow-down"
          class="dashboard__main__header__button__down__svg"
        />
      </div>
    </div>

    <div
      class="dashboard__main__header__dropdown__list"
      :class="{ active: showDropdown }"
    >
      <div class="dashboard__main__header__dropdown__list__item">
        <div class="header__user">
          <div class="user__initials header__user__initials">KP</div>
          <div class="header__user__titles">
            <div class="header__user__titles__name">Katie Pena</div>
            <p class="header__user__titles__position">Department Lead</p>
          </div>
        </div>
      </div>
      <div class="dashboard__main__header__dropdown__list__item__divide"></div>
      <div class="dashboard__main__header__dropdown__list__item">
        <button
          @click="logout"
          class="dashboard__main__header__dropdown__list__item__link"
        >
          <BaseIcon
            class="dashboard__main__header__dropdown__list__item__link__img"
            icon="header/logout"
          />
          <span> Logout </span>
        </button>
      </div>
    </div>
  </button>
</template>

<script>
import { computed, defineComponent } from "vue";
import BaseIcon from "@/components/svg/BaseIcon";
import { useStore } from "vuex";
import getEnv from "@/utils/env";

export default defineComponent({
  name: "HeaderDropdown",
  components: { BaseIcon },
  setup() {
    const store = useStore();

    const toggleDropdown = () => {
      store.dispatch("dropdowns/toggleDropdown", "header_user");
    };

    const showDropdown = computed(() => {
      return store.state.dropdowns.header_user;
    });

    const logout = () => {
      store.dispatch("auth/logout").then(() => {
        const COGNITO_CLIENT_ID = getEnv("VUE_APP_COGNITO_CLIENT_ID");
        const COGNITO_APP_DOMAIN = getEnv("VUE_APP_COGNITO_APP_DOMAIN");
        const COGNITO_REDIRECT_URI = getEnv("VUE_APP_COGNITO_REDIRECT_URI");
        const APP_URL = getEnv("VUE_APP_APP_URL");
        window.location.href = `https://${COGNITO_APP_DOMAIN}/logout?client_id=${COGNITO_CLIENT_ID}&logout_uri=${APP_URL}&redirect_uri=${COGNITO_REDIRECT_URI}&response_type=code`;
      });
    };

    return { showDropdown, toggleDropdown, logout };
  },
});
</script>

<style scoped></style>
