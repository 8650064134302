import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/repairs/getters";
import { mutations } from "@/store/modules/repairs/mutations";
import { actions } from "@/store/modules/repairs/actions";
import { RepairsState } from "@/store/modules/repairs/types";

export const RepairsData: RepairsState = {
  repairs: [
    {
      ticket: "W001",
      type: "Maintenance",
      device: "Ventilator",
      vendor: "Phillips",
      issued: "22 Jun 2020",
      closed: "22 Jun 2020",
      repair_cost: "$5,318.00",
      status: "Issued",
    },
    {
      ticket: "W002",
      type: "Repair",
      device: "Ventilator",
      vendor: "GE",
      issued: "15 May 2020",
      closed: "15 May 2020",
      repair_cost: "$2,409.00",
      status: "Issued",
    },
    {
      ticket: "W003",
      type: "Maintenance",
      device: "Nasal Cannula",
      vendor: "Phillips",
      issued: "15 May 2020",
      closed: "15 May 2020",
      repair_cost: "$2,109.00",
      status: "Collected",
    },
    {
      ticket: "W004",
      type: "Maintenance",
      device: "BiPap Device",
      vendor: "Phillips",
      issued: "15 May 2020",
      closed: "15 May 2020",
      repair_cost: "$1,078.00",
      status: "Repaired",
    },
    {
      ticket: "W005",
      type: "Maintenance",
      device: "Nasal Cannula",
      vendor: "Phillips",
      issued: "13 May 2020",
      closed: "13 May 2020",
      repair_cost: "$5,510.00",
      status: "Repaired",
    },
    {
      ticket: "W006",
      type: "Repair",
      device: "Ventilator",
      vendor: "GE",
      issued: "28 May 2020",
      closed: "28 May 2020",
      repair_cost: "$8,837.00",
      status: "Repaired",
    },
    {
      ticket: "W007",
      type: "Maintenance",
      device: "Ventilator",
      vendor: "GE",
      issued: "10 May 2020",
      closed: "10 May 2020",
      repair_cost: "$724.59",
      status: "Collected",
    },
    {
      ticket: "W008",
      type: "Maintenance",
      device: "Ventilator",
      vendor: "Getinge",
      issued: "08 Mar 2020",
      closed: "08 Mar 2020",
      repair_cost: "$710.32",
      status: "Repaired",
    },
    {
      ticket: "W009",
      type: "Repair",
      device: "Ventilator",
      vendor: "Getinge",
      issued: "02 Mar 2019",
      closed: "02 Mar 2019",
      repair_cost: "$3,034.00",
      status: "Issued",
    },
    {
      ticket: "W010",
      type: "Repair",
      device: "Nasal Cannula",
      vendor: "Getinge",
      issued: "15 Feb 2020",
      closed: "15 Feb 2020",
      repair_cost: "$805.00",
      status: "Collected",
    },
  ],
};

export const repairs: Module<RepairsState, RootState> = {
  namespaced: true,
  state: RepairsData,
  getters,
  actions,
  mutations,
};

export default repairs;
