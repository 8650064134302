
import { defineComponent } from "vue";
import BaseLayout from "@/components/layout/BaseLayout.vue";
import MultiLineChart from "@/components/charts/MultiLineChart.vue";
import DoughnutChart from "@/components/charts/DoughnutChart.vue";
import Filters from "@/components/resusables/Filters.vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    BaseIcon,
    Filters,
    MultiLineChart,
    DoughnutChart,
    BaseLayout,
  },
  setup() {
    const chartData1 = {
      datasets: [
        {
          data: [71, 28, 43, 4],
          backgroundColor: ["#016667", "#DAB75D", "#B5CBCB", "#DAE5E6"],
        },
      ],
      labels: ["Available", "Missing", "In-use", "Repair"],
    };
    const chartData2 = {
      datasets: [
        {
          data: [144, 66, 88, 4],
          backgroundColor: ["#016667", "#DAB75D", "#B5CBCB", "#DAE5E6"],
        },
      ],
      labels: ["Available", "Missing", "In-use", "Repair"],
    };

    return {
      chartData1,
      chartData2,
    };
  },
});
