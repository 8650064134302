import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { DropdownState } from "@/store/modules/dropdowns/types";

export const getters: GetterTree<DropdownState, RootState> = {
  headerUser(state: DropdownState): boolean {
    return state.header_user;
  },
  headerNotification(state: DropdownState): boolean {
    return state.header_notification;
  },
};
