
import { defineComponent, onMounted, ref, watch } from "vue";
import BaseLayout from "@/components/layout/BaseLayout.vue";
import TableComponent from "@/components/resusables/Table.vue";
import DoughnutChart from "@/components/charts/DoughnutChart.vue";
import FiltersDevices from "@/components/resusables/FiltersDevices.vue";
import { useStore } from "vuex";
import router from "@/router";

export default defineComponent({
  name: "DevicesPage",
  components: {
    DoughnutChart,
    FiltersDevices,
    TableComponent,
    BaseLayout,
  },

  setup() {
    const isOpen = ref(false);
    const dataIndex = ref(0);
    const store = useStore();
    const filter = ref({
      organizations: "",
      departments: "",
      category: "",
    });

    const fields = [
      { key: "serial", label: "Serial", sortable: true },
      { key: "category", label: "Category", sortable: true },
      { key: "vendor", label: "Vendor", sortable: true },
      { key: "PO", label: "PO", sortable: true },
      { key: "verified", label: "Verified", sortable: true },
      { key: "purchase", label: "Purchase", sortable: true },
      { key: "service", label: "Service", sortable: true },
      { key: "rental", label: "Rental", sortable: true },
      { key: "parts", label: "Maintenance", sortable: true },
      { key: "total_costs", label: "Total Costs", sortable: true },
      // { key: "status", label: "Status", sortable: true },
    ];

    onMounted(() => {
      getDevices();
    });

    const openDevice = () => {
      isOpen.value = true;
      router.push({ name: "ViewDevice", params: { id: "234419003" } });
    };

    const closeDevice = () => {
      isOpen.value = false;
      router.push({ name: "Devices" });
    };

    const devices = ref(store.state.devices.devices);

    const getDevices = () => {
      store.dispatch("devices/getDevices");
    };

    const devicesCartData = [
      {
        costOfOwnerShip: "$150,013,932",
        avgCostOfOwnerShip: "$10,742",
        serviceContractSavings: "$5,156,400",
        rentalSavings: "$350,454",
      },
      {
        costOfOwnerShip: "$7,995,030",
        avgCostOfOwnerShip: "$17,322",
        serviceContractSavings: "$1,650,454",
        rentalSavings: "$98,416",
      },
      {
        costOfOwnerShip: "$5,013,932",
        avgCostOfOwnerShip: "$34,342",
        serviceContractSavings: "$131,320",
        rentalSavings: "$19,416",
      },
    ];

    const changeData = (length, filterVal) => {
      dataIndex.value = length - 1;
      filter.value = filterVal;
      if (filter.value.category.includes("Ventilators")) {
        devices.value = store.state.devices.devices.filter((device) => {
          return device.category === "Ventilator";
        });
      } else {
        devices.value = store.state.devices.devices;
      }
    };

    watch([filter], () => {
      if (filter.value.category.includes("Ventilators")) {
        devices.value = store.state.devices.devices.filter((device) => {
          return device.category === "Ventilator";
        });
      } else {
        devices.value = store.state.devices.devices;
      }
    });

    const chartDataList = [
      {
        datasets: [
          {
            data: [6889, 2727, 4162, 574],
            backgroundColor: ["#016667", "#DAB75D", "#B5CBCB", "#DAE5E6"],
          },
        ],
        labels: ["Available", "Missing", "In-use", "Repair"],
      },
      {
        datasets: [
          {
            data: [242, 88, 116, 19],
            backgroundColor: ["#016667", "#DAB75D", "#B5CBCB", "#DAE5E6"],
          },
        ],
        labels: ["Available", "Missing", "In-use", "Repair"],
      },
      {
        datasets: [
          {
            data: [71, 28, 43, 4],
            backgroundColor: ["#016667", "#DAB75D", "#B5CBCB", "#DAE5E6"],
          },
        ],
        labels: ["Available", "Missing", "In-use", "Repair"],
      },
    ];

    return {
      isOpen,
      openDevice,
      closeDevice,
      devices,
      chartDataList,
      devicesCartData,
      fields,
      dataIndex,
      changeData,
    };
  },
});
