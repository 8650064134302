<template>
  <div id="dashboard">
    <div class="dashboard">
      <DashboardSidebar />
      <div class="dashboard__main">
        <DashboardHeader />
        <main class="dashboard__main__content">
          <slot></slot>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import DashboardHeader from "@/components/layout/header/DashboardHeader";
import DashboardSidebar from "@/components/layout/DashboardSidebar";

export default defineComponent({
  name: "BaseLayout",
  components: { DashboardSidebar, DashboardHeader },
  setup() {
    const drawer = ref(true);

    return {
      drawer,
    };
  },
});
</script>

<style scoped></style>
