<template>
  <aside class="dashboard__sidebar">
    <div class="dashboard__sidebar__logo">
      <img
        class="dashboard__sidebar__logo__img"
        src="@/assets/images/logo/logo.png"
        alt=""
      />
    </div>
    <div class="dashboard__sidebar__menu">
      <router-link :key="key" v-for="(link, key) in links" :to="link.route">
        <div
          class="dashboard__sidebar__menu__item group"
          :class="{ active: route.path === link.route }"
        >
          <BaseIcon
            :icon="link.icon"
            class="dashboard__sidebar__menu__item__img"
          />
          <a href="" class="dashboard__sidebar__menu__item__link">{{
            link.name
          }}</a>
        </div>
      </router-link>
    </div>
  </aside>
</template>

<script>
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import BaseIcon from "@/components/svg/BaseIcon";
export default defineComponent({
  name: "DashboardSidebar",
  components: { BaseIcon },
  setup() {
    const route = useRoute();

    const links = [
      {
        icon: "sidebar/dashboard",
        route: "/dashboard",
        name: "Dashboard",
      },
      {
        icon: "sidebar/devices",
        route: "/devices",
        name: "All Devices",
      },
      {
        icon: "sidebar/repairs",
        route: "/repairs",
        name: "Repair Tickets",
      },
      {
        icon: "sidebar/contracts",
        route: "/contracts",
        name: "Contracts",
      },
    ];

    return {
      route,
      links,
    };
  },
});
</script>

<style scoped></style>
