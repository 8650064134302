
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import BaseRadio from "@/components/form/BaseRadio.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import DateRangePicker from "@/components/form/DateRangePicker.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import { useStore } from "vuex";
import BaseCheckbox from "@/components/form/BaseCheckbox.vue";

export default defineComponent({
  name: "FiltersComponent",
  components: {
    BaseCheckbox,
    BaseIcon,
    BaseInput,
    DateRangePicker,
    BaseLabel,
    BaseRadio,
  },

  setup(props, context) {
    const store = useStore();
    const activeFilter = ref(0);

    const selecting = ref({
      organizations: "Maimonides Medical Center",
      departments: "Respiratory",
      category: ["Ventilators", "BiPAPs"],
    });

    const selectedItems = ref({
      organizations: "Maimonides Medical Center",
      departments: "Respiratory",
      category: ["Ventilators", "BiPAPs"],
    });

    const filters = [
      {
        name: "Organizations",
        field: "organizations",
        dependent: false,
        list: ["Maimonides Medical Center", "Organisation 2", "Organisation 3"],
      },
      {
        name: "Departments",
        field: "departments",
        dependent: false,
        list: ["Respiratory", "CCU - Cardiac Care", "Surgery"],
      },
      {
        name: "Category",
        field: "category",
        dependent: true,
        list: {
          Respiratory: ["Ventilators", "BiPAPs", "Nasal Cannulas"],
          "CCU - Cardiac Care": [
            "EKGs",
            "Telemetry Monitors",
            "Infusion Pumps",
            "LVAD Machines",
          ],
          Surgery: ["Bladder scanner", "Impella", "Dopplers"],
        },
      },
    ];

    onMounted(() => {
      const length = Object.values(selectedItems.value).filter(
        (val) => val && val.length > 0
      ).length;
      context.emit("apply_filters", length, selectedItems.value);
    });

    watch(
      () => ({ ...selecting.value }),
      (newVal, oldVal) => {
        if (oldVal.departments !== newVal.departments) {
          selecting.value["category"] = [];
          selectedItems.value["category"] = [];
        }
      }
    );

    const showFilter = computed(() => {
      return store.state.dropdowns.filters;
    });

    const selectFilter = (index: number) => {
      if (activeFilter.value === index) {
        activeFilter.value = -1;
      } else {
        activeFilter.value = index;
      }
    };

    const check = (
      field: string,
      value: string,
      dep: boolean,
      checked: boolean
    ) => {
      if (!dep) {
        selecting.value[field] = value;
      } else {
        const result = [...selecting.value[field]];
        if (checked) {
          selecting.value[field] = [...selecting.value[field], value];
        } else {
          result.splice(result.indexOf(value), 1);
          selecting.value[field] = result;
        }
      }
    };

    const removeFilter = (field: string, index = -1) => {
      if (index >= 0) {
        selectedItems.value[field].splice(index, 1);
        selecting.value[field].splice(index, 1);
      } else {
        selectedItems.value[field] = null;
        selecting.value[field] = null;
      }
      const length = Object.values(selectedItems.value).filter(
        (val) => val && val.length > 0
      ).length;
      context.emit("apply_filters", length, selectedItems.value);
    };

    const clearFilter = () => {
      selectedItems.value = {
        organizations: "",
        departments: "",
        category: [],
      };
      selecting.value = {
        organizations: "",
        departments: "",
        category: [],
      };
      context.emit("apply_filters", 1, selectedItems.value);
    };

    const applyFilters = () => {
      selectedItems.value = { ...selecting.value };
      const values = Object.values(selectedItems.value).filter(
        (val) => val && val.length > 0
      ).length;
      context.emit("apply_filters", values, selectedItems.value);
    };

    const toggleFilter = () => {
      store.dispatch("dropdowns/toggleDropdown", "filters");
    };

    return {
      showFilter,
      toggleFilter,
      filters,
      activeFilter,
      selectFilter,
      check,
      selectedItems,
      removeFilter,
      selecting,
      applyFilters,
      clearFilter,
    };
  },
});
