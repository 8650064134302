import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/contracts/getters";
import { mutations } from "@/store/modules/contracts/mutations";
import { actions } from "@/store/modules/contracts/actions";
import { ContractsState } from "@/store/modules/contracts/types";

export const ContractsData: ContractsState = {
  contracts: [
    {
      po: "61441295",
      req: "1031",
      type: "Purchase",
      vendor: "VAPOTHERM, INC.",
      category: "High Flows",
      subcategory: "Vapotherm",
      device_account: "52",
      unverified: "2",
      start_date: "22 Jun 2020",
      end_date: "22 Aug 2020",
      yearly_cost: "$1,340",
      savings: "$35,435",
      status: "Ended",
    },
    {
      po: "67111424",
      req: "9345",
      type: "Service",
      device_account: "28",
      vendor: "Maquet Critical Care AB",
      category: "Ventilators",
      subcategory: "SERVO-U",
      unverified: "1",
      start_date: "15 May 2020",
      end_date: "22 Aug 2020",
      yearly_cost: "$4500",
      savings: "$36,845",
      status: "Active",
    },
    {
      po: "55960037",
      req: "2007",
      type: "Service",
      vendor: "Maquet Critical Care AB",
      category: "Ventilators",
      subcategory: "SERVO-U",
      device_account: "76",
      unverified: "0",
      start_date: "15 May 2020",
      end_date: "22 Aug 2020",
      yearly_cost: "$1,340",
      savings: "$2,958",
      status: "Active",
    },
    {
      po: "66057747",
      req: "8367",
      type: "Purchase",

      device_account: "45",
      vendor: "VAPOTHERM, INC.",
      category: "High Flows",
      subcategory: "Vapotherm",
      unverified: "1",
      start_date: "15 May 2020",
      end_date: "15 May 2020",
      yearly_cost: "$560",
      savings: "$3,503",
      status: "Active",
    },
    {
      po: "66057747",
      req: "9361",
      type: "Rentals",
      vendor: "Vendor 1",
      category: "Infusion Pumps",
      subcategory: "Carefusion",
      device_account: "1,345",
      unverified: "3",
      start_date: "13 May 2020",
      end_date: "13 May 2023",
      yearly_cost: "$845,367",
      savings: "$634,025",
      status: "Active",
    },
    {
      po: "67446838",
      type: "Purchase",
      req: "1662",
      vendor: "Maquet Critical Care AB",
      category: "Ventilators",
      subcategory: "SERVO-U",
      device_account: "77",
      unverified: "2",
      start_date: "28 Mar 2020",
      end_date: "28 Mar 2020",
      yearly_cost: "$4,450",
      savings: "$38,540",
      status: "Ended",
    },
    {
      po: "56093066",
      req: "1859",
      type: "Purchase",
      vendor: "Maquet Critical Care AB",
      category: "Ventilators",
      subcategory: "SERVO-U",
      device_account: "55",
      unverified: "5",
      start_date: "10 Mar 2020",
      end_date: "10 Mar 2020",
      yearly_cost: "$4,450",
      savings: "$38,540",
      status: "Active",
    },
    {
      po: "98465996",
      req: "1641",
      type: "Rentals",
      vendor: "Vendor 2",
      category: "PCA Pumps",
      subcategory: "SD3000",
      device_account: "920",
      unverified: "0",
      start_date: "08 Mar 2020",
      end_date: "08 Mar 2024",
      yearly_cost: "$485,953",
      savings: "$364,464",
      status: "Active",
    },
    {
      po: "94068492",
      req: "7487",
      type: "Purchase",
      vendor: "Maquet Critical Care AB",
      category: "Ventilators",
      subcategory: "SERVO-U",
      device_account: "13",
      unverified: "4",
      start_date: "02 Mar 2019",
      end_date: "02 Mar 2019",
      yearly_cost: "$3,200",
      savings: "$31,550",
      status: "Ended",
    },
    {
      po: "60434735",
      req: "3923",
      type: "Purchase",
      vendor: "VAPOTHERM, INC.",
      category: "High Flows",
      subcategory: "Vapotherm",
      device_account: "65",
      unverified: "4",
      start_date: "15 Feb 2020",
      end_date: "15 Feb 2020",
      yearly_cost: "$1,200",
      savings: "$3,323",
      status: "Active",
    },
    //  ---------------
  ],
};

export const contracts: Module<ContractsState, RootState> = {
  namespaced: true,
  state: ContractsData,
  getters,
  actions,
  mutations,
};

export default contracts;
