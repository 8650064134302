import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "@/store/modules/auth";
import dropdowns from "@/store/modules/dropdowns";
import contracts from "@/store/modules/contracts";
import { repairs } from "@/store/modules/repairs";
import devices from "@/store/modules/devices";

export default createStore({
  state: {
    data: "new",
  },
  actions: {},
  mutations: {},
  modules: {
    auth,
    devices,
    repairs,
    contracts,
    dropdowns,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => localStorage.getItem(key),
        setItem: (key, state) => localStorage.setItem(key, state),
        removeItem: (key) => localStorage.removeItem(key),
      },
    }),
  ],
});
