import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { ContractsData } from "@/store/modules/contracts/index";
import { ContractsState } from "@/store/modules/contracts/types";

export const actions: ActionTree<ContractsState, RootState> = {
  getContracts({ commit }) {
    commit("SET_CONTRACTS", ContractsData.contracts);
    return ContractsData.contracts;
  },
};
