
import { defineComponent, ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";

export default defineComponent({
  name: "ViewContract",
  components: { BaseIcon },
  props: ["contract"],

  setup(props, context) {
    const showUnVerified = ref(true);

    const close = () => {
      context.emit("close");
    };

    const unverified = [39268, 40591, 39271];
    const verified = [39272, 40595, 40837, 40831, 40830];

    const toggle = (value) => {
      showUnVerified.value = value;
    };

    return {
      close,
      showUnVerified,
      unverified,
      verified,
      toggle,
    };
  },
});
