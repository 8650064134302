<template>
  <div class="table__container devices__container__table">
    <h4 class="table__container__title">{{ name }}</h4>

    <div class="table__wrapper">
      <table class="table devices__table">
        <thead class="table__head">
          <tr class="table__column table__head__row">
            <th :key="key" v-for="(field, key) in fields">
              {{ field.label }}
              <span v-if="key === 0" class="table__head__row__field__sort"
                ><BaseIcon icon="arrow/down"
              /></span>
            </th>
          </tr>
        </thead>
        <tbody class="table__body">
          <tr
            @click="open(row)"
            :key="key"
            v-for="(row, key) in data"
            class="table__body__row table__column group"
          >
            <td
              :key="key2"
              :class="addStatusClass(field.key, row[field.key])"
              v-for="(field, key2) in fields"
            >
              <span>{{ row[field.key] }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";

export default defineComponent({
  name: "TableComponent",
  components: {
    BaseIcon,
  },
  props: {
    data: {
      required: false,
      type: Array,
      default: () => [],
    },
    fields: {
      required: false,
      type: Array,
      default: () => [],
    },
    name: {
      required: true,
      type: String,
    },
  },

  setup(props, context) {
    const addStatusClass = (field, value) => {
      if (field === "status") {
        if (value === "Issued") {
          return "table__status__danger";
        } else if (["Collected", "Ended"].includes(value)) {
          return "table__status__warning";
        } else if (["Repaired", "Active"].includes(value)) {
          return "table__status__success";
        }
      }
    };

    const open = (row) => {
      context.emit("open", row);
    };

    return {
      open,
      addStatusClass,
    };
  },
});
</script>

<style scoped></style>
