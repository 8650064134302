
import { computed, defineComponent, onMounted, ref, watch } from "vue";

// Components
import BaseLayout from "@/components/layout/BaseLayout.vue";
import TableComponent from "@/components/resusables/Table.vue";
// import Filters from "@/components/resusables/Filters.vue";
import DoughnutChart from "@/components/charts/DoughnutChart.vue";
import { useStore } from "vuex";
import router from "@/router";

export default defineComponent({
  name: "RepairTicketsPage",
  components: {
    // Filters,
    TableComponent,
    BaseLayout,
    DoughnutChart,
  },

  setup() {
    const store = useStore();
    const isOpen = ref(false);
    const contractsType = ref("all");
    const contractFilterType = ref("categories");
    const contracts = ref(store.state.contracts.contracts);

    watch(
      () => contractsType.value,
      (newVal, oldVal) => {
        if (newVal === "rentals") {
          contracts.value = store.state.contracts.contracts.filter(
            (con) => con.type === "Rentals"
          );
        } else {
          contracts.value = store.state.contracts.contracts;
        }
      }
    );

    const fields = [
      { key: "po", label: "PO #", sortable: true },
      { key: "type", label: "Type", sortable: true },
      { key: "vendor", label: "Vendor", sortable: true },
      { key: "category", label: "Category", sortable: true },
      { key: "subcategory", label: "Subcategory", sortable: true },
      { key: "device_account", label: "# of Devices", sortable: true },
      { key: "unverified", label: "# of Unverified", sortable: true },
      { key: "start_date", label: "Start Date", sortable: true },
      { key: "end_date", label: "End Date", sortable: true },
      { key: "yearly_cost", label: "Cost", sortable: true },
      { key: "savings", label: "Savings", sortable: true },
      { key: "status", label: "Status", sortable: true },
    ];

    const chartData = {
      datasets: [
        {
          data: [38688, 65040, 6557, 73647, 139],
          backgroundColor: [
            "#016667",
            "#DAB75D",
            "#B5CBCB",
            "#DAE5E6",
            "#DAE5E6",
          ],
        },
      ],
      labels: ["Purchases", "Rentals", "Service", "Maintenance", "Disposition"],
    };

    const categoriesChartData = {
      datasets: [
        {
          data: [639033, 386082, 239637, 53252, 1331],
          backgroundColor: [
            "#016667",
            "#DAB75D",
            "#B5CBCB",
            "#DAE5E6",
            "#DAE5E6",
          ],
        },
      ],
      labels: [
        "Infusion Pumps",
        "PCA Pumps",
        "CVVHDs",
        "Impellas",
        "Bladder Scanners",
      ],
    };
    const departmentsChartData = {
      datasets: [
        {
          data: [639033, 386082, 239637, 53252, 1331],
          backgroundColor: [
            "#016667",
            "#DAB75D",
            "#B5CBCB",
            "#DAE5E6",
            "#DAE5E6",
          ],
        },
      ],
      labels: ["CTICU", "CCU", "MICU", "SICU", "PACU"],
    };

    const vendorsChartData = {
      datasets: [
        {
          data: [639033, 386082, 239637, 53252, 1331],
          backgroundColor: [
            "#016667",
            "#DAB75D",
            "#B5CBCB",
            "#DAE5E6",
            "#DAE5E6",
          ],
        },
      ],
      labels: ["Vendor 1", "Vendor 2", "Vendor 3", "Vendor 4", "Vendor 5"],
    };

    onMounted(() => {
      getContracts();
    });

    // let contracts = computed(() => {
    //   return store.state.contracts.contracts;
    // });

    const allContracts = computed(() => {
      return store.state.contracts.contracts;
    });

    const getContracts = () => {
      store.dispatch("contracts/getContracts");
    };

    const closeDevice = () => {
      isOpen.value = false;
      router.push({ name: "ServiceContracts" });
    };

    const openContract = () => {
      isOpen.value = true;
      router.push({ name: "ViewContract", params: { id: "234419003" } });
    };

    const selectFilterType = (value) => {
      contractFilterType.value = value;
    };

    const onContractsTypeChange = () => {
      console.log("Value: ", contractFilterType.value);
    };

    return {
      fields,
      isOpen,
      contracts,
      chartData,
      closeDevice,
      openContract,
      contractsType,
      onContractsTypeChange,
      contractFilterType,
      selectFilterType,
      categoriesChartData,
      departmentsChartData,
      vendorsChartData,
    };
  },
});
