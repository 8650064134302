<template>
  <div class="dashboard__notifications">
    <button
      @click="toggleNotification"
      class="dashboard__notifications__button"
    >
      <BaseIcon icon="header/notifications" />
    </button>

    <div
      class="dashboard__notifications__list"
      :class="{ active: showNotification }"
    >
      <div class="dashboard__notifications__list__header">
        <h2 class="dashboard__notifications__list__header__title">
          Notifications
        </h2>
        <a class="dashboard__notifications__list__header__link" href=""
          >Mark all as read</a
        >
      </div>
      <div class="dashboard__notifications__list__body">
        <div class="dashboard__notifications__list__body__item">
          <div class="dashboard__notifications__list__body__item__img">
            <BaseIcon
              icon="header/notification-user"
              class="dashboard__notifications__list__body__item__img_svg"
            />
          </div>
          <div class="dashboard__notifications__list__body__item__content">
            <h5 class="dashboard__notifications__list__body__item__message">
              Ventilator by Phillips <span>has been issued by</span>
            </h5>
            <h5 class="dashboard__notifications__list__body__item__user">
              John Doe
            </h5>
            <div class="dashboard__notifications__list__body__item__footer">
              <a
                href=""
                class="dashboard__notifications__list__body__item__link"
                >Ticket# - W001</a
              >
              <h6 class="dashboard__notifications__list__body__item__date">
                <span>.</span> Yesterday
              </h6>
            </div>
          </div>
        </div>
        <div class="dashboard__notifications__list__body__item">
          <div class="dashboard__notifications__list__body__item__content">
            <h5 class="dashboard__notifications__list__body__item__message">
              Service contract for REQ 1031 <span>has been ended</span>
            </h5>
            <h5 class="dashboard__notifications__list__body__item__user">
              John Doe
            </h5>
            <div class="dashboard__notifications__list__body__item__footer">
              <a
                href=""
                class="dashboard__notifications__list__body__item__link"
                >Ticket# - W001</a
              >
              <h6 class="dashboard__notifications__list__body__item__date">
                <span>.</span> Yesterday
              </h6>
            </div>
          </div>
        </div>
        <div class="dashboard__notifications__list__body__item">
          <div class="dashboard__notifications__list__body__item__img">
            <BaseIcon
              icon="header/notification-user"
              class="dashboard__notifications__list__body__item__img_svg"
            />
          </div>
          <div class="dashboard__notifications__list__body__item__content">
            <h5 class="dashboard__notifications__list__body__item__message">
              Ventilator by Phillips <span>has been issued by</span>
            </h5>
            <h5 class="dashboard__notifications__list__body__item__user">
              John Doe
            </h5>
            <div class="dashboard__notifications__list__body__item__footer">
              <a
                href=""
                class="dashboard__notifications__list__body__item__link"
                >Ticket# - W001</a
              >
              <h6 class="dashboard__notifications__list__body__item__date">
                <span>.</span> <span>Yesterday</span>
              </h6>
            </div>
          </div>
        </div>

        <div class="dashboard__notifications__list__body__item">
          <div class="dashboard__notifications__list__body__item__img">
            <BaseIcon
              icon="header/notification-user"
              class="dashboard__notifications__list__body__item__img_svg"
            />
          </div>
          <div class="dashboard__notifications__list__body__item__content">
            <h5 class="dashboard__notifications__list__body__item__message">
              Ventilator by Phillips <span>has been issued by</span>
            </h5>
            <h5 class="dashboard__notifications__list__body__item__user">
              John Doe
            </h5>
            <div class="dashboard__notifications__list__body__item__footer">
              <a
                href=""
                class="dashboard__notifications__list__body__item__link"
                >Ticket# - W001</a
              >
              <h6 class="dashboard__notifications__list__body__item__date">
                <span>.</span> Yesterday
              </h6>
            </div>
          </div>
        </div>
        <div class="dashboard__notifications__list__body__item">
          <div class="dashboard__notifications__list__body__item__content">
            <h5 class="dashboard__notifications__list__body__item__message">
              Service contract for REQ 1031 <span>has been ended</span>
            </h5>
            <h5 class="dashboard__notifications__list__body__item__user">
              John Doe
            </h5>
            <div class="dashboard__notifications__list__body__item__footer">
              <a
                href=""
                class="dashboard__notifications__list__body__item__link"
                >Ticket# - W001</a
              >
              <h6 class="dashboard__notifications__list__body__item__date">
                <span>.</span> Yesterday
              </h6>
            </div>
          </div>
        </div>
        <div class="dashboard__notifications__list__body__item">
          <div class="dashboard__notifications__list__body__item__img">
            <BaseIcon
              icon="header/notification-user"
              class="dashboard__notifications__list__body__item__img_svg"
            />
          </div>
          <div class="dashboard__notifications__list__body__item__content">
            <h5 class="dashboard__notifications__list__body__item__message">
              Ventilator by Phillips <span>has been issued by</span>
            </h5>
            <h5 class="dashboard__notifications__list__body__item__user">
              John Doe
            </h5>
            <div class="dashboard__notifications__list__body__item__footer">
              <a
                href=""
                class="dashboard__notifications__list__body__item__link"
                >Ticket# - W001</a
              >
              <h6 class="dashboard__notifications__list__body__item__date">
                <span>.</span> <span>Yesterday</span>
              </h6>
            </div>
          </div>
        </div>

        <div class="dashboard__notifications__list__body__item">
          <div class="dashboard__notifications__list__body__item__img">
            <BaseIcon
              icon="header/notification-user"
              class="dashboard__notifications__list__body__item__img_svg"
            />
          </div>
          <div class="dashboard__notifications__list__body__item__content">
            <h5 class="dashboard__notifications__list__body__item__message">
              Ventilator by Phillips <span>has been issued by</span>
            </h5>
            <h5 class="dashboard__notifications__list__body__item__user">
              John Doe
            </h5>
            <div class="dashboard__notifications__list__body__item__footer">
              <a
                href=""
                class="dashboard__notifications__list__body__item__link"
                >Ticket# - W001</a
              >
              <h6 class="dashboard__notifications__list__body__item__date">
                <span>.</span> Yesterday
              </h6>
            </div>
          </div>
        </div>
        <div class="dashboard__notifications__list__body__item">
          <div class="dashboard__notifications__list__body__item__content">
            <h5 class="dashboard__notifications__list__body__item__message">
              Service contract for REQ 1031 <span>has been ended</span>
            </h5>
            <h5 class="dashboard__notifications__list__body__item__user">
              John Doe
            </h5>
            <div class="dashboard__notifications__list__body__item__footer">
              <a
                href=""
                class="dashboard__notifications__list__body__item__link"
                >Ticket# - W001</a
              >
              <h6 class="dashboard__notifications__list__body__item__date">
                <span>.</span> Yesterday
              </h6>
            </div>
          </div>
        </div>
        <div class="dashboard__notifications__list__body__item">
          <div class="dashboard__notifications__list__body__item__img">
            <BaseIcon
              icon="header/notification-user"
              class="dashboard__notifications__list__body__item__img_svg"
            />
          </div>
          <div class="dashboard__notifications__list__body__item__content">
            <h5 class="dashboard__notifications__list__body__item__message">
              Ventilator by Phillips <span>has been issued by</span>
            </h5>
            <h5 class="dashboard__notifications__list__body__item__user">
              John Doe
            </h5>
            <div class="dashboard__notifications__list__body__item__footer">
              <a
                href=""
                class="dashboard__notifications__list__body__item__link"
                >Ticket# - W001</a
              >
              <h6 class="dashboard__notifications__list__body__item__date">
                <span>.</span> <span>Yesterday</span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import BaseIcon from "@/components/svg/BaseIcon";
import { useStore } from "vuex";

export default defineComponent({
  name: "HeaderNotifications",
  components: { BaseIcon },
  setup() {
    const store = useStore();

    const showNotification = computed(() => {
      return store.state.dropdowns.header_notification;
    });

    const toggleNotification = () => {
      store.dispatch("dropdowns/toggleDropdown", "header_notification");
    };

    return { showNotification, toggleNotification };
  },
});
</script>

<style scoped></style>
