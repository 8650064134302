
import { ref } from "vue";
import { defineComponent } from "vue";
import { ElDatePicker } from "element-plus";
import moment from "moment";

export default defineComponent({
  components: { ElDatePicker },

  setup() {
    const date = ref([]);

    const isDateDisabled = (date) => {
      return moment(date).isAfter(moment().format("YYYY-MM-DD"));
    };

    return {
      date,
      isDateDisabled,
    };
  },
});
