import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { DevicesState } from "@/store/modules/devices/types";
import { DevicesData } from "@/store/modules/devices/index";

export const actions: ActionTree<DevicesState, RootState> = {
  getDevices({ commit }) {
    commit("SET_DEVICES", DevicesData.devices);
    return DevicesData.devices;
  },
};
