import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/devices/getters";
import { mutations } from "@/store/modules/devices/mutations";
import { actions } from "@/store/modules/devices/actions";
import { DevicesState } from "@/store/modules/devices/types";

export const DevicesData: DevicesState = {
  devices: [
    {
      serial: "36998745N",
      category: "Ventilator",
      vendor: "Phillips",
      PO: "61441295",
      verified: "No",
      rental: "No",
      purchase: "$33,350",
      service: "$1,340",
      parts: "$745",
      total_costs: "$35,435",
      status: "Repaired",
    },
    {
      serial: "41236988T",
      category: "Ventilator",
      vendor: "Getinge",
      PO: "43511424",
      verified: "No",
      rental: "$1,618",
      purchase: "$0",
      service: "$1,340",
      parts: "$0",
      total_costs: "$2,958",
      status: "Issued",
    },
    {
      serial: "78896541D",
      category: "Nasal Cannula",
      vendor: "GE",
      PO: "67111424",
      verified: "Yes",
      rental: "No",
      purchase: "$32,345",
      service: "$4,500",
      parts: "$0",
      total_costs: "$36,845",
      status: "Collected",
    },
    {
      serial: "57895123E",
      category: "BiPap Device",
      vendor: "Philips",
      PO: "57895123E",
      verified: "No",
      rental: "No",
      purchase: "$2,600",
      service: "$560",
      parts: "$343",
      total_costs: "$3,503",
      status: "Collected",
    },
    {
      serial: "4789632Q1",
      category: "Nasal Cannula",
      vendor: "Philips",
      PO: "62150126",
      verified: "Yes",
      rental: "$,1618",
      purchase: "No",
      service: "$1,340",
      parts: "$0",
      total_costs: "$2,958",
      status: "Collected",
    },
    {
      serial: "62150126",
      category: "Ventilator",
      vendor: "GE",
      PO: "67446838",
      verified: "Yes",
      rental: "No",
      purchase: "$32,345",
      service: "$4,450",
      parts: "$1,745",
      total_costs: "$38,540",
      status: "Collected",
    },
    {
      serial: "78963214G",
      category: "Ventilator",
      vendor: "GE",
      PO: "56093066",
      verified: "Yes",
      rental: "No",
      purchase: "$32,345",
      service: "$4,450",
      parts: "$558",
      total_costs: "$37,353",
      status: "Collected",
    },
    {
      serial: "78945612D",
      category: "Ventilator",
      vendor: "Getinge",
      PO: "98465996",
      verified: "Yes",
      rental: "No",
      purchase: "$28,350",
      service: "$3,200",
      parts: "$343",
      total_costs: "$31,893",
      status: "Collected",
    },
    {
      serial: "58741101D",
      category: "Ventilator",
      vendor: "Getinge",
      PO: "94068492",
      verified: "Yes",
      rental: "No",
      purchase: "$28,350",
      service: "$3,200",
      parts: "$0",
      total_costs: "$31,550",
      status: "Collected",
    },
    {
      serial: "11478963Q",
      category: "Nasar Cannula",
      vendor: "Getinge",
      PO: "60434735",
      verified: "Yes",
      rental: "No",
      purchase: "No",
      service: "$1,200",
      parts: "$0",
      total_costs: "$1,200",
      status: "Collected",
    },
  ],
};

export const devices: Module<DevicesState, RootState> = {
  namespaced: true,
  state: DevicesData,
  getters,
  actions,
  mutations,
};

export default devices;
