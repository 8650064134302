
import { defineComponent } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import BaseCheckbox from "@/components/form/BaseCheckbox.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";

export default defineComponent({
  name: "ViewRepairTicket",
  components: { BaseLabel, BaseCheckbox, BaseIcon },

  setup(props, context) {
    const close = () => {
      context.emit("close");
    };
    return {
      close,
    };
  },
});
