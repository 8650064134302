<template>
  <div class="doughnut__chart__list">
    <Doughnut
      class="doughnut-chart"
      :chart-data="chartData"
      :chart-options="chartOptions"
      :plugins="[counter]"
    />
    <div class="doughnut__labels">
      <h3 v-if="title" class="doughnut__title">{{ title }}</h3>
      <div
        :key="key"
        v-for="(dataset, key) in datasets"
        class="doughnut__labels__item"
      >
        <div class="doughnut__labels__item__group">
          <span
            class="doughnut__labels__item__color"
            :style="{ backgroundColor: dataset.color }"
          ></span>
          <span class="doughnut__labels__item__label">{{ dataset.label }}</span>
        </div>

        <div class="doughnut__labels__item__group">
          <span class="doughnut__labels__item__value">{{
            isCurrency
              ? `$${formatMoney(dataset.value)}`
              : formatMoney(dataset.value)
          }}</span>
          <span class="doughnut__labels__item__percentage">{{
            dataset.percentage
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
} from "chart.js";
// import Chart from "chart.js";
import { computed, defineComponent } from "vue";
import { helpers } from "@/composables/helpers";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
);

export default defineComponent({
  name: "DoughnutChart",
  components: { Doughnut },
  props: {
    width: {
      type: Number,
      default: 244,
    },
    height: {
      type: Number,
      default: 244,
    },
    total: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    isCurrency: {
      type: Boolean,
      default: false,
      required: false,
    },
    totalLabel: {
      type: String,
      required: false,
      default: "Total devices",
    },
    chartData: {
      type: Object,
      required: false,
      default: () => ({
        datasets: [
          {
            data: [48, 19, 29, 4],
            backgroundColor: ["#016667", "#DAB75D", "#B5CBCB", "#DAE5E6"],
          },
        ],
        labels: ["Available", "Missing", "In-use", "Repair"],
      }),
    },
    chartOptions: {
      type: Object,
      default: () => ({
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        // maintainAspectRatio: false,
        // animation: {
        //   animateRotate: false,
        // },
      }),
    },
  },

  setup(props) {
    const datasets = computed(() => {
      const totalSum = props.chartData.datasets[0].data.reduce((a, b) => {
        return a + b;
      }, 0);
      return props.chartData.datasets[0].data.map((value, i) => {
        return {
          value,
          percentage: `(${Math.round((value / totalSum) * 100)}%)`,
          color: props.chartData.datasets[0].backgroundColor[i],
          label: props.chartData.labels[i],
        };
      });
    });

    const { formatMoney } = helpers();

    const counter = {
      beforeDraw(chart) {
        const {
          ctx,
          chartArea: { height, width },
        } = chart;

        const fontSize = (height / 170).toFixed(2);
        ctx.font = fontSize + "em Arial";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "rgba(0, 0, 0, 0.87)";

        let total;

        if (props.total) {
          total = formatMoney(props.total);
        } else {
          let sum = JSON.parse(JSON.stringify(props.chartData.datasets[0]));

          total = formatMoney(
            props.chartData.datasets[0].data.reduce((a, b) => {
              return a + b;
            }, 0)
          );
        }
        total = props.isCurrency ? `$${total}` : total;

        const textX = Math.round((width - ctx.measureText(total).width) / 2);
        const textY = height / 2 - 8;

        ctx.fillText(total, textX, textY);

        const text2 = props.totalLabel;
        ctx.font = "12px sans-serif";
        ctx.fillStyle = "rgba(0, 0, 0, 0.6)";
        const textX2 = Math.round((width - ctx.measureText(text2).width) / 2);

        ctx.fillText(text2, textX2, textY + 25);

        ctx.save();
      },
    };

    return {
      datasets,
      counter,
      formatMoney,
    };
  },
});
</script>
