
import { defineComponent } from "vue";
// import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "OAuthCallback",

  mounted() {
    const route = this.$route;

    const router = useRouter();
    const store = useStore();

    const query = route.query;
    this.$log.info({ query });
    console.log({ query });

    store.commit("auth/SET_AUTH_USER", {
      name: "User",
    });

    router.push({ name: "Dashboard" });
    //TODO call dispatch endpoint
  },

  computed: {
    ...mapGetters("auth", ["isClient", "isAdmin"]),
  },
});
