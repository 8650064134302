
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseInput",

  props: {
    modelValue: {
      type: [String, Number],
      default: "",
      required: false,
    },
  },
});
