
import { computed, defineComponent, onMounted, ref } from "vue";

// Components
import BaseLayout from "@/components/layout/BaseLayout.vue";
import TableComponent from "@/components/resusables/Table.vue";
import MultiLineChart from "@/components/charts/MultiLineChart.vue";
import Filters from "@/components/resusables/Filters.vue";
import router from "@/router";
import { useStore } from "vuex";

export default defineComponent({
  name: "RepairTicketsPage",
  components: {
    Filters,
    MultiLineChart,
    TableComponent,
    BaseLayout,
  },

  setup() {
    const isOpen = ref(false);
    const store = useStore();

    const fields = [
      { key: "ticket", label: "Ticket", sortable: true },
      { key: "type", label: "Type", sortable: true },
      { key: "device", label: "Device", sortable: true },
      { key: "vendor", label: "Vendor", sortable: true },
      { key: "issued", label: "Issued", sortable: true },
      { key: "closed", label: "Closed", sortable: true },
      { key: "repair_cost", label: "Repair Cost", sortable: true },
      { key: "status", label: "Status", sortable: true },
    ];

    const labels1 = [
      { color: "#DAB75D", label: "Opened", value: 14 },
      { color: "#B5CBCB", label: "In Progress", value: 6 },
      { color: "#016667", label: "Closed", value: 859 },
    ];

    const labels2 = [
      { color: "#016667", label: "Response", value: 57, unit: "mins" },
      { color: "#B5CBCB", label: "Diagnosis", value: 6, unit: "hrs" },
      { color: "#DAE5E6", label: "Repair", value: 2, unit: "days" },
    ];

    const chartData = {
      labels: ["Jan", "Feb", "Mar", "April", "May", "June"],
      datasets: [
        {
          label: "Dataset 1",
          data: [130, 145, 130, 125, 125, 130],
          borderColor: "#000000",
          backgroundColor: "#000000",
          yAxisID: "y",
        },
        {
          label: "Dataset 2",
          data: [50, 60, 55, 60, 70, 80],
          borderColor: "#016667",
          backgroundColor: "#016667",
          yAxisID: "y",
        },
        {
          label: "Dataset 3",
          data: [65, 50, 47, 45, 40, 45],
          borderColor: "#A9CDCD",
          backgroundColor: "#A9CDCD",
          yAxisID: "y",
        },
        {
          label: "Dataset 4",
          data: [45, 40, 43, 40, 35, 30, 30, 35, 30, 35, 30, 30],
          borderColor: "#E9B32A",
          backgroundColor: "#E9B32A",
          yAxisID: "y",
        },
        {
          label: "Dataset 5",
          data: [20, 25, 23, 20, 15, 20, 10, 15, 20, 15, 20, 15],
          borderColor: "#D0E2E2",
          backgroundColor: "#D0E2E2",
          yAxisID: "y",
        },
      ],
    };

    const chartOptions = {
      // responsive: true,
      maintainAspectRatio: false,

      interaction: {
        mode: "index",
        intersect: false,
      },
      stacked: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: "",
        },
      },
      scales: {
        y: {
          type: "linear",
          display: true,
          position: "right",
          min: 0,
          ticks: {
            stepSize: 50,
          },
        },
        y1: {
          type: "linear",
          display: false,
          position: "right",
          // grid line settings
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
      },
    };

    onMounted(() => {
      getRepairs();
    });

    const openRepairTicket = () => {
      isOpen.value = true;
      router.push({ name: "ViewRepairTicket", params: { id: "234419003" } });
    };

    const repairs = computed(() => {
      return store.state.repairs.repairs;
    });

    const getRepairs = () => {
      store.dispatch("repairs/getRepairs");
    };

    const closeRepairTicket = () => {
      isOpen.value = false;
      router.push({ name: "RepairTickets" });
    };

    return {
      openRepairTicket,
      isOpen,
      fields,
      repairs,
      labels1,
      labels2,
      closeRepairTicket,
      chartData,
      chartOptions,
    };
  },
});
