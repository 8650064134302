import { MutationTree } from "vuex";
import { RepairsState } from "@/store/modules/repairs/types";

export const mutations: MutationTree<RepairsState> = {
  SET_REPAIRS(state: RepairsState, data: object[]) {
    state.repairs = data;

    return state.repairs;
  },
};
