import { MutationTree } from "vuex";
import { AuthState } from "@/store/modules/auth/types";

export const mutations: MutationTree<AuthState> = {
  SET_AUTH_USER(state: AuthState, user: object) {
    if (user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      state.user = null;

      localStorage.removeItem("user");
    }
    return state.user;
  },
};
