
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseRadio",

  props: {
    checked: {
      type: Boolean,
    },
  },
});
