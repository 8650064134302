import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { init as SentryInit, vueRouterInstrumentation } from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { loadFonts } from "./plugins/webfontloader";
import "./assets/tailwind.css";
import VueLogger from "vuejs3-logger";
import getEnv from "@/utils/env.js";

loadFonts();

const app = createApp(App);

SentryInit({
  app,
  environment: getEnv("VUE_APP_ENVIRONMENT"),
  dsn: getEnv("VUE_APP_SENTRY_DSN"),
  integrations: [
    new BrowserTracing({
      routingInstrumentation: vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "dev.mmc.squidiq.us", /^\//],
    }),
  ],
  tracingOptions: {
    trackComponents: true,
  },
  tracesSampleRate: 1.0,
  attachProps: true,
  attachStacktrace: true,
});

app.config.globalProperties.$filters = {
  currencyUSD(value) {
    return "$" + value;
  },
};

const isProduction = getEnv("VUE_APP_ENVIRONMENT") === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};
// Vue.prototype.$moment = window.moment = moment;

app.use(router).use(store).use(VueLogger, options).mount("#app");
