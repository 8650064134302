import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { RepairsData } from "@/store/modules/repairs/index";
import { RepairsState } from "@/store/modules/repairs/types";

export const actions: ActionTree<RepairsState, RootState> = {
  getRepairs({ commit }) {
    commit("SET_REPAIRS", RepairsData.repairs);
    return RepairsData.repairs;
  },
};
